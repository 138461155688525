import React from 'react'
import { useInView } from 'react-intersection-observer'

interface LazyLoadProps {
  children?: any
  className?: string
}

export function LazyLoad(props: LazyLoadProps): React.ReactElement | null {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '700px 0px',
  })

  return (
    <div ref={ref} className={`${props.className || ''} ${inView ? 'visible' : ''}`}>
      {inView ? props.children : null}
    </div>
  )
}
