import React from 'react'
import type { ResponsiveImageType } from 'react-datocms'
import { buildSrcSetFromSrc } from '@/components/image/srcImageUtils'

interface Props {
  responsiveImage: ResponsiveImageType
  className?: string
  sizes?: string[]
}

const srcSetCandidates = [0.5, 0.75, 1, 1.5, 2]

export function EagerDatoImage(props: Props): React.ReactNode {
  const { responsiveImage, sizes = ['100vw'] } = props

  if (!responsiveImage) return null

  const srcSet = buildSrcSetFromSrc(responsiveImage.src, responsiveImage.width, srcSetCandidates)

  return (
    <picture>
      <source srcSet={srcSet} sizes={sizes.join(',')} />
      <img
        className={props.className}
        src={responsiveImage.src!}
        alt={responsiveImage.alt || ''}
        title={responsiveImage.title || undefined}
        loading="eager"
        fetchPriority="high"
      />
    </picture>
  )
}
