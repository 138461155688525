'use client'
import './Clients.scss'
import React from 'react'

const clients: string[] =
  'ACNE, ADIDAS, ALEXANDER WANG, ANOTHER MAGAZINE, ARKET, ASKET, BEAUTY PAPERS, BITE STUDIOS, BYREDO, CDLP, CHIMI, DANIEL WELLINGTON, DIANE VON FURSTENBERG, DIOR, ELECTROLUX, ENGELBERT, FEMALE ENGINEERING, FLATTERED, H&M, INTERVIEW, JULIA HETTA, KENZO, KLARNA, KLÄTTERMUSEN, LISA YANG, LUGANO, MARIMEKKO, MARTINE ROSE, MASSIMO DUTTI, MICHAEL KORS, NESPRESSO, NETFLIX, NK, PEAK PERFORMANCE, PLICK, RAVE REVIEW, SELECTED, SWAROVSKI, VOUGE, ZALANDO, ZARA, BURBERRY, MARC O’POLO, LOUIS VUITTON, GRAFF DIAMONDS, NORDSTROM, BRIONI, ESTÉE LAUDER, LONGCHAMP, TOMMY HILFIGER, MONCLER'
    .split(', ')
    .sort()
export function Clients(): React.ReactElement {
  return (
    <section className="clients">
      <h2 className="clients__heading">clients</h2>

      <div className="clients__list">
        {clients.map(client => (
          <div className="clients__item" key={client}>
            <p className="clients__name">{client}</p>
            <p className="clients__slash">/</p>
          </div>
        ))}
      </div>
    </section>
  )
}
