'use client'
import { _range } from '@naturalcycles/js-lib'
import { useWindowSize } from '@uidotdev/usehooks'
import React from 'react'
import { HomeNormalBlock } from '@/components/HomeNormalBlock'
import { GalleryQuery } from '@/data/model'

interface HomeDuplexBlockProps {
  galleries: GalleryQuery[]
  galleryPath?: string
  index: number
  col1className?: string
  col2className?: string
}

export function HomeDuplexBlock(props: HomeDuplexBlockProps): React.ReactElement {
  const { galleries, galleryPath } = props
  const windowSize = useWindowSize()
  const width = windowSize.width || 0
  const isMobile = width <= 600

  const [gal1, gal2, gal3, gal4] = galleries as [
    GalleryQuery,
    GalleryQuery,
    GalleryQuery,
    GalleryQuery,
  ]

  if (isMobile) {
    const [i1, i2, i3, i4] = _range(props.index, props.index + 4)

    return (
      <>
        <HomeNormalBlock gallery={gal1} galleryPath={galleryPath} index={i1} />
        <HomeNormalBlock gallery={gal2} galleryPath={galleryPath} index={i2} />
        <HomeNormalBlock gallery={gal3} galleryPath={galleryPath} index={i3} />
        <HomeNormalBlock gallery={gal4} galleryPath={galleryPath} index={i4} />
      </>
    )
  }

  return (
    <>
      <div className={props.col1className}>
        <HomeNormalBlock gallery={gal1} galleryPath={galleryPath} />
        <HomeNormalBlock gallery={gal2} galleryPath={galleryPath} />
      </div>

      <div className={props.col2className}>
        <HomeNormalBlock gallery={gal3} galleryPath={galleryPath} />
        <HomeNormalBlock gallery={gal4} galleryPath={galleryPath} />
        {/* </div>*/}
      </div>
    </>
  )
}
