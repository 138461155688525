'use client'
import { useWindowSize } from '@uidotdev/usehooks'
import { clsx } from 'clsx'
import Link from 'next/link'
import React from 'react'
import { DatoImage } from '@/components/image/DatoImage'
import { VimeoPlayer } from '@/components/VimeoPlayer'
import { GalleryQuery } from '@/data/model'

interface HomeBlockProps {
  gallery: GalleryQuery
  galleryPath?: string
  index?: number
  classNameByIndex?: string[]
}

export function HomeNormalBlock(props: HomeBlockProps): React.ReactElement {
  const { gallery, galleryPath = '', classNameByIndex } = props
  const className =
    gallery.className ||
    (props.index !== undefined && classNameByIndex
      ? classNameByIndex[props.index % classNameByIndex.length]!
      : '')
  const windowSize = useWindowSize()
  const width = windowSize.width || 0
  const isMobile = width <= 600
  const horizontal = gallery.cover?.tags?.includes('horizontal') || gallery.horizontal

  return (
    <Link
      href={`${galleryPath}${gallery.slug}`}
      scroll={false}
      className={clsx({
        grid__cell: true,
        grid__cell_horizontal: horizontal,
        grid__cell_left: gallery.left,
        grid__cell_right: !horizontal && gallery.right,
        [className]: !isMobile && className,
      })}
    >
      {gallery.video || gallery.videoCover ? (
        <VimeoPlayer video={gallery.video || gallery.videoCover!} className="grid__image" />
      ) : (
        // <video className="grid__image" autoPlay={true} muted loop playsInline={true}>
        //   <source src={gallery.video.url} type="video/mp4" />
        // </video>
        // <img className="grid__image" src={gallery.cover?.url} alt={gallery.title} loading="lazy" />

        gallery.cover && (
          <DatoImage
            data={gallery.cover.responsiveImage}
            containerSize={isMobile ? 80 : 45}
            className="grid__image"
            style={{ maxWidth: '100%' }}
          />
        )
      )}
      <div className="grid__undertext">
        <p className="grid__title">{gallery.client}</p>
        <p className="grid__author">{gallery.author}</p>
      </div>
    </Link>
  )
}
